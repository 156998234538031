<template>
  <NuxtLink
    :to="route"
    class="flex cursor-pointer select-none items-center gap-x-1.5 xl:ml-12"
    data-testid="container-icon-admin-mode"
    :class="[$attrs.class]"
  >
    <KeyAdminOff
      v-if="!isAdminMode"
      data-testid="icon-admin-mode-off"
      class="h-3.5 w-3.5"
      @click="changeAdminMode(true)"
    />
    <KeyAdminOn
      v-else
      data-testid="icon-admin-mode-on"
      class="h-3.5 w-3.5"
      @click="changeAdminMode(false)"
    />
    <span class="whitespace-nowrap text-white">Admin View</span>
  </NuxtLink>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import KeyAdminOff from '~/assets/icons/key-admin-off.svg'
import KeyAdminOn from '~/assets/icons/key-admin-on.svg'
import { AdminMenuData } from '~/constants/menu'
import { useGeneralStore } from '~/stores/general'

const generalStore = useGeneralStore()
const { setIsAdminMode } = generalStore
const { isAdminMode } = storeToRefs(generalStore)

const nuxtApp = useNuxtApp()

const menuFilteredByRoles = computed(() => {
  const arrMenuAdmin = Object.values(AdminMenuData)
  return arrMenuAdmin.filter((itemMenu) => nuxtApp.$authorization.hasAccess(itemMenu.roles))
})

const arrItemsMenu = computed(() => {
  if (menuFilteredByRoles.value.length === 0) return null
  const itemsMenu = menuFilteredByRoles.value[0].items
  return Object.values(itemsMenu)
})

const route = computed(() => {
  if (menuFilteredByRoles.value.length === 0) return '/'
  return isAdminMode.value ? '/' : `${arrItemsMenu.value[0].path}`
})

const changeAdminMode = (isAdmin) => {
  setIsAdminMode(isAdmin)
}
</script>
