<template>
  <div class="flex w-full select-none flex-col">
    <div
      class="feedback-button group flex cursor-pointer items-center gap-1 rounded-xl px-2 py-1.5"
      data-micromodal-trigger="modal-1"
      data-test="customer-feedback-modal-open"
    >
      <FeedbackIcon class="h-3.5 w-3.5" />
      <span class="text-base text-white">Feedback</span>
    </div>
    <div id="modal-1" class="modal micromodal-slide hidden" aria-hidden="true">
      <div
        class="modal-overlay fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center"
        tabindex="-1"
        data-micromodal-close
        data-test="customer-feedback-modal-overlay"
        @click="onCloseModal"
      >
        <div
          class="modal-container box-border max-h-screen overflow-y-auto rounded bg-white p-8"
          data-test="customer-feedback-modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-1-title"
        >
          <header class="modal-header flex items-center justify-between">
            <h2
              id="modal-1-title"
              data-test="customer-feedback-modal-title"
              class="modal-title text-p-blue-700 mt-0 box-border text-2xl font-semibold leading-5"
            >
              Feedback
            </h2>
            <button
              class="modal-close border-0 bg-transparent"
              data-test="customer-feedback-modal-close"
              aria-label="Close modal"
              data-micromodal-close
            ></button>
          </header>
          <form
            name="feed-back"
            method="post"
            data-netlify="true"
            enctype="multipart/form-data"
            data-netlify-honeypot="bot-field"
            @submit.prevent="handleSubmit"
          >
            <main id="modal-1-content" class="modal-content mb-8 mt-8 leading-normal">
              <input type="hidden" name="form-name" value="feed-back" />
              <div class="mb-2 pb-4">
                <label class="mb-2 block" for="category">
                  <span class="font-semibold">Categories</span>
                </label>
                <select
                  id="category"
                  v-model="form.category"
                  name="category"
                  data-test="customer-feedback-modal-select"
                  class="block min-w-full rounded border border-solid border-gray-50 p-2"
                >
                  <option value="feature">Feature Request</option>
                  <option value="observation">Industry Observation</option>
                  <option value="issue">Issue</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="mb-6 text-sm" data-test="customer-feedback-modal-category-test">
                <div v-if="form.category === 'feature'">
                  Please provide a high-level description of the new feature you would like to see,
                  including:
                  <ul class="list-disc">
                    <li class="ml-8">What new feature you want to see</li>
                    <li class="ml-8">How it would provide value to you</li>
                    <li class="ml-8">Any current work-around you are using</li>
                  </ul>
                </div>
                <div v-else-if="form.category === 'observation'">Instructions TBD</div>
                <div v-else-if="form.category === 'issue'">
                  Please describe the details of your issue and include as much of the following
                  information as possible
                  <ul class="list-disc">
                    <li class="ml-8">The actions required to recreate the issue seen</li>
                    <li class="ml-8">
                      The expected behaviour and describe how what you see differs from this
                    </li>
                    <li class="ml-8">
                      The link shown at
                      <a
                        target="_blank"
                        class="text-p-blue-100"
                        href="https://www.whatsmybrowser.org"
                        rel="noopener"
                        >https://www.whatsmybrowser.org</a
                      >
                      (navigate to this page using the same computer and browser used when seeing
                      the issue in Benchmark Pro)
                    </li>
                    <li class="ml-8">
                      If helpful please upload a screenshot or a video that shows the issue you are
                      seeing
                    </li>
                  </ul>
                </div>
                <div v-else-if="form.category === 'other'">Please provide the relevant details</div>
              </div>
              <div class="mb-2 pb-4">
                <label class="mb-2 block" for="subject">
                  <span class="font-semibold">Subject</span>
                </label>
                <input
                  id="subject"
                  v-model="form.subject"
                  name="subject"
                  data-test="customer-feedback-modal-subject"
                  class="block min-w-full rounded border border-solid border-gray-50 p-2"
                  type="text"
                />
              </div>
              <div class="mb-2 pb-4">
                <label class="mb-2 block" for="message">
                  <span class="font-semibold">Message</span>
                </label>
                <textarea
                  id="message"
                  v-model="form.message"
                  name="message"
                  data-test="customer-feedback-modal-message"
                  class="block min-w-full rounded border border-solid border-gray-50 p-2"
                  rows="9"
                ></textarea>
              </div>
              <div class="mb-2 pb-4">
                <label class="mb-2 block" for="file">
                  <span class="font-semibold">Upload</span>
                </label>
                <input
                  id="file"
                  ref="inputFile"
                  data-test="customer-feedback-modal-files"
                  type="file"
                  name="file"
                  @change="onChangeFile"
                />
              </div>
              <div v-if="isSubmittedSuccess" class="mb-2 pb-4">
                <p class="bg-p-green-400 p-4 text-white">Thank you for your feedback</p>
              </div>
            </main>
            <footer class="modal-footer">
              <button
                data-test="customer-feedback-modal-button"
                :class="isFormValid ? 'cursor-pointer' : 'cursor-not-allowed'"
                class="modal-btn bg-p-blue-700 m-0 overflow-visible rounded border-none px-6 py-4 text-lg normal-case text-white"
                aria-label="Send feedback"
                :disabled="!isFormValid"
              >
                Send
              </button>
            </footer>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MicroModal from 'micromodal'
import FeedbackIcon from '~/assets/icons/chats-teardrop.svg'

const route = useRoute()
const nuxtApp = useNuxtApp()

const form = reactive({
  category: null,
  subject: null,
  message: null,
  file: null,
})

const isSubmittedSuccess = ref(false)

const isFormValid = computed(() => {
  return form.category && form.subject && form.message
})

const inputFile = ref(null)

onMounted(() => {
  MicroModal.init({})
  const { feedback } = route.query
  if (feedback) {
    MicroModal.show('modal-1', {})
  }
})

const onChangeFile = (event) => {
  form.file = event.target.files[0]
}

const resetValues = () => {
  isSubmittedSuccess.value = false
  inputFile.value = null
  form.category = null
  form.subject = null
  form.message = null
  form.file = null
}

const encode = (data) => {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    if (key === 'file' && data[key]) {
      formData.append(key, data[key], data[key]?.name)
    } else {
      formData.append(key, data[key])
    }
  })
  return formData
}

const handleSubmit = async () => {
  try {
    const body = encode({
      'form-name': 'feed-back',
      ...form,
    })

    await nuxtApp.$axios.post('/', body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })

    isSubmittedSuccess.value = true
    setTimeout(() => {
      MicroModal.close('modal-1', {})
      resetValues()
    }, 3000)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

const onCloseModal = () => {
  const query = {
    ...route.query,
  }
  delete query.feedback
  nuxtApp.$router.push({
    ...route,
    query,
  })
}
</script>

<style scoped>
.modal-container {
  min-width: 680px;
  max-width: 680px;
}

.feedback-button:hover {
  background: linear-gradient(0deg, rgb(255 255 255 / 5%), rgb(255 255 255 / 5%)), #071830;
}
</style>
