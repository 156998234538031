<template>
  <div v-if="items.length" class="relative">
    <breadcrumb-item
      v-if="items.length > 1"
      :class="{ invisible: show }"
      :item="module"
      :is-last="isLast"
      @mouseover="show = true"
    />
    <breadcrumb-item v-else-if="items[0]" :item="items[0]" :is-last="isLast" />
    <div class="breadcrumb-multiple" :class="{ hidden: !show }" @mouseleave="show = false">
      <div v-for="(row, index) in items" :key="index">
        <breadcrumb-item v-if="row.label" class="p-1" :item="row" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    label: string
    items?: any[]
    isLast?: boolean
  }>(),
  {
    items: () => [],
    isLast: false,
  },
)

const show = ref(false)
const module = ref({
  label: props.label,
  underline: true,
})
</script>

<style>
.breadcrumb-multiple {
  @apply bg-p-blue-500 absolute w-full p-2;

  top: -10px;
}
</style>
