<template>
  <div v-if="breadcrumbItems" class="flex items-center justify-end pb-3 pt-2.5 md:justify-between">
    <div class="hidden md:block">
      <ul
        class="breadcrumbs flex items-center gap-x-2 whitespace-nowrap capitalize text-white"
        data-test="breadcrumbs"
      >
        <li v-for="(breadcrumb, index) in breadcrumbItems" :key="index + '-' + breadcrumb">
          <breadcrumb-item
            v-if="breadcrumb && !breadcrumb?.items"
            :is-last="index < breadcrumbItems.length - 1"
            :item="breadcrumb"
          />
          <breadcrumb-multiple
            v-if="breadcrumb && breadcrumb?.items"
            :is-last="index < breadcrumbItems.length - 1"
            :items="breadcrumb?.items"
            :label="breadcrumb?.label"
          />
        </li>
      </ul>
    </div>
    <div class="flex items-center gap-x-3">
      <AdminMode v-if="isAdmin" class="hidden md:flex" />
      <Feedback v-if="showFeedback" />
      <MyAccount />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useNuxtApp } from 'nuxt/app'

const { $authorization } = useNuxtApp()

withDefaults(
  defineProps<{
    breadcrumbItems: any[]
  }>(),
  {
    breadcrumbItems: () => [],
  },
)

const showFeedback = ref(false)
const isAdmin = computed(() => Boolean($authorization.hasAdminPermission()))
</script>
